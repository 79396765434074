<template lang="pug">
.container
  .w-full.mt-40.mb-40
    .grid-branding
      .card.justify-end
        .bg-card.blue
          BaseCustomIcon(nameIcon="mdiSpeedometer", :size="36")
        h1 {{ $t('homePage.branding_title_1') }}
        p {{ $t('homePage.branding_description_1') }}
      .card
        .bg-card.green
          BaseCustomIcon(nameIcon="mdiChartBarStacked", :size="36")
        h1 {{ $t('homePage.branding_title_2') }}
        p {{ $t('homePage.branding_description_2') }}
      .card.justify-end
        .bg-card.yellow
          BaseCustomIcon(nameIcon="mdiBadgeAccount", :size="36")
        h1 {{ $t('homePage.branding_title_3') }}
        p {{ $t('homePage.branding_description_3') }}
</template>
<script>
export default {
  name: 'sectionBranding'
};
</script>
<style lang="scss" scoped>
h1 {
  @apply text-2xl;
  @apply font-bold;
  @apply mb-2;
  @apply text-gray-700;
}
p {
  @apply text-sm;
}

.grid-branding {
  @apply grid grid-cols-1 gap-10 lg:grid-cols-3 lg:text-center;
}
.card {
  @apply mx-auto flex flex-col items-start max-w-lg lg:items-center;
  .bg-card {
    @apply border-4 h-20 w-20 bg-white rounded-3xl flex items-center justify-center mb-4;

    &.blue {
      @apply text-blue-500 border-blue-200 bg-blue-50;
    }
    &.green {
      @apply text-green-500 border-green-200 bg-green-50;
    }
    &.yellow {
      @apply text-yellow-500 border-yellow-200 bg-yellow-50;
    }

    &.card-big {
      @apply h-24 w-24;
    }
  }
}
</style>
